import React from "react";

const TeachingCard = (props) => {
  return (
    <div className="teaching-card">
      <div className="teaching-card-code">{props.code}</div>
      <div className="teaching-card-content">
        <h2 className="teaching-card-content-name">{props.name}</h2>
        <p className="teaching-card-content-extra medium-text">{props.extra}</p>
        <p className="teaching-card-content-year medium-text">{props.year}</p>
      </div>
    </div>
  );
};
export default TeachingCard;
