import * as React from "react";
import Layout from "../components/layout";
import Footer from "../components/footer";
import TeachingCard from "../components/teachingCard";

const Teaching = () => {
  return (
    <div>
      <Layout
        header={<h2>TEACHING</h2>}
        content={
          <div className="project-content">
            <p className="teaching-definition medium-text">
              Here is a list of various design courses I've taught at TOBB ETU
              Department of Industrial Design
            </p>
            <TeachingCard
              code="EUT 321"
              name="Third Year Design Studio I"
              extra="with Dr. Ali Emre Berkman"
              year="2017-2021"
            ></TeachingCard>
            <TeachingCard
              code="EUT 322"
              name="Third Year Design Studio II"
              extra="with Dr. Ali Emre Berkman"
              year="2017-2021"
            ></TeachingCard>{" "}
            <TeachingCard
              code="EUT 421"
              name="Fourth Year Design Studio II"
              extra="with Dr. Aydın Öztoprak"
              year="2016"
            ></TeachingCard>
            <TeachingCard
              code="EUT 425"
              name="Design Methods"
              extra="with Dr. Aydın Öztoprak"
              year="2016"
            ></TeachingCard>
            <TeachingCard
              code="EUT 215"
              name="Introduction to Visual Communication"
              extra=""
              year="2016-2021"
            ></TeachingCard>
            <TeachingCard
              code="EUT 216"
              name="Computer Aided Product Design I"
              extra=""
              year="2016-2021"
            ></TeachingCard>
            <TeachingCard
              code="EUT 317"
              name="Computer Aided Product Design II"
              extra=""
              year="2016-2021"
            ></TeachingCard>
            <TeachingCard
              code="EUT 318"
              name="Computer Aided Product Design III"
              extra=""
              year="2016-2021"
            ></TeachingCard>
          </div>
        }
      ></Layout>
      <Footer></Footer>
    </div>
  );
};

export default Teaching;
